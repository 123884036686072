<template>
  <v-card flat min-height="100vh" color="transparent">
    <v-card-title class="xflex xflex-row xjustify-center xitems-center xw-full">
      <Filters
        :value="selectedYearMonth"
        @change="navigate"
        type="monthly"
      ></Filters>
    </v-card-title>
    <v-progress-linear
      indeterminate
      v-if="fetching"
      :height="10"
      class="my-1 xw-full"
      color="primary"
    ></v-progress-linear>
    <Iterators
      v-else
      empty-text="No records found for the selected year and month"
      :data-array="records"
      type="monthly"
      class="px-4"
    ></Iterators>
    <!-- <pre>{{ records }}</pre> -->
  </v-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";
import Filters from "../components/Filters.vue";
import Iterators from "../components/Iterators.vue";
export default {
  components: { Filters, Iterators },
  data() {
    return {
      fetching: false,
      records: [],
      selectedYearMonth: moment().format("YYYY-MM"),
    };
  },
  watch: {
    "$route.params.year_month": {
      handler: function (val) {
        this.selectedYearMonth = val;
        val && this.fetchSummary(val);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["user"]),
    selectedId() {
      return this.$route.params.id;
    },
  },
  methods: {
    navigate(val) {
      this.$router
        .push({
          name: "app-user-record-monthly",
          params: { id: this.selectedId, year_month: val },
        })
        .catch(() => {});
    },
    fetchSummary(year_month) {
      this.fetching = true;
      this.$axios
        .get(`api/user-records/${this.selectedId}/monthly/${year_month}`)
        .then(({ data }) => {
          this.records = data.summaries;
        })
        .finally(() => (this.fetching = false));
    },
  },
};
</script>

<style lang="scss" scoped></style>
